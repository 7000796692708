import { FunctionComponent, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import OpponentCard from "./OpponentCard";
import { getOpponentVideosBySongId } from "../../api/opponents";
import { OpponentVideo } from "../../models/OpponentVideo";
import { challengeToBattle } from "../../api/challenges";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import { Helmet } from "react-helmet-async";
import VideoSelector from "./VideoSelector";
import { UserVideo } from "../../models/UserVideo";
import { getVideoById } from "../../api/videos";
import { getUserStats } from "../../api/users";
import SuccessModal from "../SuccessModal";
import { Dot } from "@phosphor-icons/react";
import { useBadges } from "../../providers/BadgesProvider";

const FindOpponent: FunctionComponent = () => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const location = useLocation();
  const { badges } = useBadges();
  const { videoId } = useParams();
  const preselectedVideo = location.state?.preselectedVideo;
  const [selectedVideo, setSelectedVideo] = useState<UserVideo | null>(
    preselectedVideo || null
  );
  const [opponentVideos, setOpponentVideos] = useState<OpponentVideo[]>([]);
  const [loading, setLoading] = useState(false); // Initialize loading as false
  const [error, setError] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [filteredOpponents, setFilteredOpponents] = useState<OpponentVideo[]>(
    []
  );
  const [opponentName, setOpponentName] = useState("");
  const [noOpponentsFound, setNoOpponentsFound] = useState(false);
  const [userStats, setUserStats] = useState<{ points: number } | null>(null);

  const fetchUserStats = async () => {
    if (authState.user) {
      try {
        const stats = await getUserStats(authState.user?.id);
        setUserStats(stats);
      } catch (error) {
        setLoading(false);
        new Error("Failed to fetch user stats");
      }
    }
  };

  // Fetch video by videoId from URL
  const fetchVideoById = async (id: string) => {
    try {
      const accessToken = tokens?.access_token || "";
      const videoData = await getVideoById(accessToken, id); // Fetch video details
      setSelectedVideo(videoData);
    } catch (error) {
      console.error("Failed to fetch video:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (videoId) {
      fetchVideoById(videoId);
    }
  }, [videoId, tokens?.access_token]);

  useEffect(() => {
    fetchUserStats();
    if (selectedVideo) {
      fetchOpponents();
    }
  }, [selectedVideo, tokens?.access_token, authState.user]);

  const fetchOpponents = () => {
    if (!selectedVideo) return;

    setLoading(true); // Set loading to true when starting to fetch opponents
    const accessToken = tokens?.access_token || "";

    getOpponentVideosBySongId(accessToken, selectedVideo.song.id)
      .then((opponentVideos: OpponentVideo[]) => {
        setOpponentVideos(opponentVideos);
        setFilteredOpponents(opponentVideos);
        setNoOpponentsFound(opponentVideos.length === 0);
      })
      .catch((error) => {
        console.error("Fetch opponents failed:", error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false); // Ensure loading is set to false once fetching is complete
      });
  };

  const filterOpponents = (query: string) => {
    const filtered = opponentVideos.filter(
      (opponentVideo: OpponentVideo) =>
        opponentVideo.user.firstName
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        opponentVideo.user.lastName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOpponents(filtered);
  };

  const handleChallengeToBattleClick = async (
    opponentVideoId: number,
    opponentName: string
  ) => {
    try {
      const accessToken = tokens?.access_token || "";
      if (selectedVideo) {
        await challengeToBattle(
          accessToken,
          Number(selectedVideo.id),
          opponentVideoId
        );
        setOpponentName(opponentName);

        setShowSuccessModal(true);
        fetchOpponents(); // Refresh opponents after challenge
      }
    } catch (error) {
      console.error("Challenge to battle failed:", error);
    }
  };

  const handleVideoSelect = (video: UserVideo) => {
    setSelectedVideo(video); // Update the selected video
  };

  return (
    <>
      <Helmet>
        <title>Find opponent</title>
      </Helmet>

      <div className="w-100 p-4 px-md-5 w-100 border-bottom text-lg-center">
        <h2 className="fw-semibold fs-1">Find Opponents</h2>
        <p className="m-0 text-secondary">
          Challenge others to guitar battles.{" "}
          <br className="d-none d-lg-block" />
          Invite your friends to vote for you to win the battle and earn
          rewards.
        </p>
      </div>

      <div className="p-4 px-md-5 d-flex justify-content-center w-100">
        <div className="p-4 border border-warning rounded-4 bg-dark-light">
          <VideoSelector onSelectVideo={handleVideoSelect} />
          {selectedVideo && (
            <div className="d-flex flex-column gap-3 mt-4 align-items-center justify-content-center">
              <div
                className="col rounded-3 overflow-hidden"
                style={{ maxWidth: "240px" }}
              >
                <AWSVideoPlayerForm
                  videoUrl={selectedVideo.videoUrl}
                  thumbnailUrl={selectedVideo.thumbnailUrl}
                  isPrivate={true}
                />
              </div>
              <div className="d-flex align-items-center gap-1 ">
                <p className="m-0 fw-semibold text-truncate">
                  <small>{selectedVideo.song?.artistName || " "}</small>
                </p>
                <Dot size={12} />
                <p className="m-0 text-secondary text-truncate">
                  <small>{selectedVideo.song?.songTitle || " "}</small>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <>
        {loading ? (
          <div className="p-4 px-md-5 text-center w-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : noOpponentsFound ? (
          <p className="p-4 px-md-5 text-center w-100">
            No opponents found for this video.
          </p>
        ) : (
          <div className="p-4 px-md-5 w-100">
            <div className="row">
              {filteredOpponents.length > 0 ? (
                filteredOpponents.map((opponentVideo) => (
                  <OpponentCard
                    key={opponentVideo.id}
                    opponentVideo={opponentVideo}
                    buttonDisabled={!userStats || userStats.points <= 50}
                    allBadges={badges}
                    onChallengeToBattleClick={handleChallengeToBattleClick}
                  />
                ))
              ) : (
                <p className="text-center px-5">
                  Opponents will be shown when you select one of your recorded
                  videos to battle.
                </p>
              )}
            </div>
          </div>
        )}
      </>

      {/* Success Modal */}
      <SuccessModal
        title={<></>}
        text={
          <>
            <h3 className="m-0 fs-1 fw-semibold">
              You've successfully challenged <br />
              <span className="text-yellow">{opponentName}</span> to battle!
            </h3>
            <p className="m-0 text-secondary">
              Waiting for your opponent to accept. In the meantime, challenge
              others to keep the action going!
            </p>
          </>
        }
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
      />
    </>
  );
};

export default FindOpponent;
