import React, { createContext, useContext, useEffect, useState } from "react";
import { getBadges } from "../api/badges"; // API call to fetch badges
import { Badge } from "../models/Badge"; // Badge model

interface BadgesContextType {
  badges: Badge[];
  reloadBadges: () => Promise<void>; // Manual reload if needed
}

const BadgesContext = createContext<BadgesContextType | undefined>(undefined);

// Provider component
export const BadgesProvider = ({ children }: { children: React.ReactNode }) => {
  const [badges, setBadges] = useState<Badge[]>([]);

  // Function to fetch badges from API
  const loadBadges = async () => {
    try {
      const fetchedBadges = await getBadges();
      setBadges(fetchedBadges);
    } catch (error) {
      console.error("Failed to load badges", error);
    }
  };

  // Load badges once on app startup
  useEffect(() => {
    loadBadges();
  }, []);

  return (
    <BadgesContext.Provider value={{ badges, reloadBadges: loadBadges }}>
      {children}
    </BadgesContext.Provider>
  );
};

// Custom hook for easy access
export const useBadges = () => {
  const context = useContext(BadgesContext);
  if (!context) {
    throw new Error("useBadges must be used within a BadgesProvider");
  }
  return context;
};
