import React from "react";
import { Sword } from "@phosphor-icons/react";

interface BattlesSmallDisplayProps {
  wins?: number;
  total?: number;
}

const BattlesSmallDisplay: React.FC<BattlesSmallDisplayProps> = ({
  wins = 0,
  total = 0,
}) => {
  return (
    <span>
      <small className="d-flex align-items-center text-yellow gap-1">
        <Sword size={18} /> {wins} / {total}
      </small>
    </span>
  );
};

export default BattlesSmallDisplay;
