import { FunctionComponent, useState } from "react";
import { User } from "../../../models/User";
import { Trophy, Heart, Dot, LockSimple, Eye } from "@phosphor-icons/react";
import { UserStats } from "../../../models/UserStats";
import UserBadgesSmallDisplay from "./UserBadgesSmallDisplay";
import BattlesSmallDisplay from "./BattlesSmallDisplay";
import OffcanvasButton from "../../components/OffcanvasButton";
import Offcanvas from "../../components/Offcanvas";
import PublicProfile from "../../components/PublicProfile";
import AWSVideoPlayerForm from "../../components/AWSVideoPlayerForm";
import { Badge } from "../../../models/Badge";

export enum PlayerStatus {
  WINNER = "WINNER",
  TIE = "TIE",
  LOSER = "LOSER",
}

export type PlayerSongCardProps = {
  user: User;
  userStats: UserStats;
  likes: number;
  playerStatus?: PlayerStatus;
  song: {
    artistName: string;
    songTitle: string;
  };
  videoUrl?: string;
  thumbnailUrl?: string;
  tabType: string;
  allBadges: Badge[];
};

const PlayerSongCard: FunctionComponent<PlayerSongCardProps> = ({
  user,
  userStats,
  likes,
  playerStatus,
  song,
  tabType,
  videoUrl,
  thumbnailUrl,
  allBadges,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const offcanvasIdPublicProfile = `publicProfile-${user.id}`;
  const offcanvasIdVideo = `video-${videoUrl}`;

  console.log(userStats);
  return (
    <div>
      <OffcanvasButton
        targetId={offcanvasIdPublicProfile}
        className="bg-transparent p-0 border-0"
        label={
          <div className="d-flex align-items-center gap-3">
            <div>
              <img
                width="40"
                height="40"
                className="rounded-3 img-fluid"
                crossOrigin="anonymous"
                alt={user?.firstName || ""}
                src={user?.pictureUrl || "/profile_placeholder.png"}
              />
            </div>
            <div>
              <h3 className="mb-1 fs-6 fw-medium text-start">
                <small>
                  {user?.firstName} {user?.lastName}
                </small>
              </h3>

              <div className="user-info d-flex align-items-center gap-2">
                {/* Show badges if user has any  */}
                <UserBadgesSmallDisplay
                  userBadges={userStats?.badges}
                  allBadges={allBadges}
                />
                <BattlesSmallDisplay
                  wins={userStats?.winningBattles || 0}
                  total={userStats?.totalBattles || 0}
                />
                {/* 
                  <span>
                    <small className="d-flex align-items-center text-yellow gap-1">
                      <Trophy size={18} /> 29 / 756
                    </small>
                  </span> 
                */}
              </div>
            </div>
          </div>
        }
      />
      <Offcanvas id={offcanvasIdPublicProfile}>
        <PublicProfile
          user={user}
          userStats={userStats}
          allBadges={allBadges}
        />
      </Offcanvas>

      <div className="d-flex align-items-center mt-2 gap-2">
        {videoUrl && (
          <>
            <OffcanvasButton
              targetId={offcanvasIdVideo}
              icon={<Eye size={20} />}
              className="bg-transparent border-0 p-0 m-0 text-warning lh-sm d-flex align-items-center"
            />
            <Offcanvas
              id={offcanvasIdVideo}
              onShow={() => setIsVideoLoaded(true)}
              onHide={() => setIsVideoLoaded(false)}
            >
              <div className="p-4 px-md-5 h-75">
                {isVideoLoaded && (
                  <AWSVideoPlayerForm
                    videoUrl={videoUrl}
                    thumbnailUrl={thumbnailUrl}
                    isPrivate={true}
                    autoplay={false}
                  />
                )}
              </div>
            </Offcanvas>
          </>
        )}

        <div className="d-flex align-items-center gap-1">
          <p className="m-0 fw-semibold text-truncate">
            <small>{song?.artistName || ""}</small>
          </p>
          <Dot size={12} />
          <p className="m-0 text-secondary text-truncate">
            <small>{song?.songTitle || ""}</small>
          </p>
        </div>
        <div>
          {likes >= 0 && (
            <span>
              {tabType === "FINISHED" &&
              (playerStatus == "WINNER" || playerStatus == "TIE") ? (
                <small className="rounded-3 bg-secondary text-black fw-light d-flex align-items-center gap-1 px-2 py-1 mh-26">
                  {playerStatus == "TIE" ? "Tie" : "Winner"}{" "}
                  <Heart size={14} weight="fill" /> {likes}
                </small>
              ) : (
                <small className="rounded-3 bg-secondary text-black fw-light d-flex align-items-center gap-1 px-2 py-1 mh-26">
                  <Heart size={14} weight="fill" /> {likes}
                </small>
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerSongCard;
