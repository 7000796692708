import { forwardRef } from "react";
import { formatDate } from "../../../utility/DateFormatter";
import { Battle } from "../../../models/Battle";
import PlayerSongCard, { PlayerStatus } from "./PlayerSongCard";
import SocialShare from "./SocialShare";
import { ArrowCircleRight, ShareNetwork } from "@phosphor-icons/react";
import {
  checkIsBattleLikedByMe,
  generateSocialShareTitle,
} from "../utility/BattlesUtility";
import OffcanvasButton from "../../components/OffcanvasButton";
import Offcanvas from "../../components/Offcanvas";
import { Badge } from "../../../models/Badge";

const url = `${window.location.origin}${location.pathname}`;

export type BattleCardProps = {
  battle: Battle;
  type: string;
  onBattleClick: (battle: Battle) => void;
  currentUserId?: string;
  allBadges: Badge[]
};

const BattleCard = forwardRef<HTMLDivElement, BattleCardProps>(
  function BattleCard({ battle, type, onBattleClick, currentUserId, allBadges }, ref) {
    const getStartingPlayerStatus = (battle: Battle): PlayerStatus => {
      if (battle.startingOpponent.likes > battle.opponent.likes) {
        return PlayerStatus.WINNER;
      } else if (battle.startingOpponent.likes < battle.opponent.likes) {
        return PlayerStatus.LOSER;
      } else {
        return PlayerStatus.TIE;
      }
    };

  const getOpponentPlayerStatus = (battle: Battle): PlayerStatus => {
    if (battle.opponent.likes > battle.startingOpponent.likes) {
      return PlayerStatus.WINNER;
    } else if (battle.opponent.likes < battle.startingOpponent.likes) {
      return PlayerStatus.LOSER;
    } else {
      return PlayerStatus.TIE;
    }
  };

  const isUserInBattle = currentUserId !== undefined &&
    (Number(currentUserId) === battle.startingOpponent?.user.id ||
      Number(currentUserId) === battle.opponent?.user.id);

  return (
    <div ref={ref} className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="bg-dark-light rounded-4 p-3 h-100">
        <span className="m-0 text-secondary">
          <small>
            {type === "FINISHED"
              ? `Ended at ${formatDate(new Date(battle.endedAt))}`
              : `Ends ${formatDate(new Date(battle.endedAt))}`}
          </small>
        </span>
        <div className="pt-3 d-flex flex-column gap-3">
          <PlayerSongCard
            user={battle.startingOpponent?.user}
            userStats={battle.startingOpponent?.userStats}
            likes={battle.startingOpponent?.likes || 0}
            playerStatus={getStartingPlayerStatus(battle)}
            song={battle.startingOpponent?.video?.song}
            videoUrl={battle.startingOpponent?.video?.videoUrl}
            thumbnailUrl={battle.startingOpponent?.video?.thumbnailUrl}
            tabType={type}
            allBadges={allBadges}
          />
          <PlayerSongCard
            user={battle.opponent?.user}
            userStats={battle.opponent?.userStats}
            likes={battle.opponent?.likes || 0}
            playerStatus={getOpponentPlayerStatus(battle)}
            song={battle.opponent?.video?.song}
            videoUrl={battle.opponent?.video?.videoUrl}
            thumbnailUrl={battle.opponent?.video?.thumbnailUrl}
            tabType={type}
            allBadges={allBadges}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <OffcanvasButton
            targetId="socialShare"
            label={<small>Share battle</small>}
            icon={<ShareNetwork size={20} />}
            className="d-flex gap-2 align-items-center bg-transparent p-0 border-0 text-yellow"
          />
          <Offcanvas id="socialShare">
            <SocialShare url={url} title={generateSocialShareTitle(battle)} />
          </Offcanvas>
          <button
            onClick={() => onBattleClick(battle)}
            role="button"
            className="bg-transparent border-0 text-yellow fs-6 p-0"
          >
            <small>
              {type === "FINISHED" || checkIsBattleLikedByMe(battle.id)
                ? "View"
                : "View & vote"}{" "}
              <ArrowCircleRight size={24} />
            </small>
          </button>
        </div>
      </div>
    </div>
  );
  }
);

export default BattleCard;
