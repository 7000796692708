import { FunctionComponent, useMemo, type CSSProperties, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { ArrowUpRight, Bell } from "@phosphor-icons/react";
import { getUserStats } from "../../api/users";
import { UserStats } from "../../models/UserStats";

interface UserMenuProps {
  notificationsCount: number;
  refreshNotifications: () => void; // Function to update count
}

const UserMenu: FunctionComponent<UserMenuProps> = ({
  notificationsCount,
  refreshNotifications,
}) => {
  const { authState, logout } = useAuth();
  const navigate = useNavigate();
  const [userStats, setUserStats] = useState<UserStats | null>(null);

  /** Action props */

  const onProfileClick = useCallback(() => {
    navigate("/my-profile");
  }, [navigate]);

  const onMyDashboardClick = useCallback(() => {
    navigate("/my-dashboard");
  }, [navigate]);

  const onAccountTextClick = useCallback(() => {
    // Please sync "learn songs --- single" to the project
  }, []);

  const onNotificationsClick = useCallback(() => {
    navigate("/notifications");
  }, []);

  useEffect(() => {
    fetchUserStats();
    refreshNotifications();
  }, [authState.user, refreshNotifications]);

  //TODO refactor into a hook as AuthProvider
  const fetchUserStats = async () => {
    if (authState.user) {
      try {
        const stats = await getUserStats(authState.user?.id);
        setUserStats(stats);
      } catch (error) {
        new Error("Failed to fetch user stats");
      }
    }
  };

  //   useEffect(() => {
  //     console.log(" 2 Updated notificationsCount state:", notificationsCount);
  //   }, [notificationsCount]);

  return (
    <div className="usermenu border-bottom">
      <div className="d-flex px-4 px-md-5 py-3 justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
          <a className="user-avatar" href="#" onClick={onProfileClick}>
            <img
              crossOrigin="anonymous"
              src={authState.user?.picture || "/profile_placeholder.png"}
              alt="mdo"
              width="40"
              height="40"
              className="rounded-3"
            />
          </a>
          <a
            className="d-flex flex-column h-100 justify-content-between"
            href="#"
            onClick={onMyDashboardClick}
          >
            <p className="m-0 text-secondary">
              <small>Points</small>
            </p>
            <p className="m-0 text-yellow">
              {userStats?.points || 0}{" "}
              <sup>
                <ArrowUpRight size={16} />
              </sup>
            </p>
          </a>
          <a
            className="d-flex flex-column"
            href="#"
            onClick={onMyDashboardClick}
          >
            <p className="m-0 text-secondary">
              <small>Battles won</small>
            </p>
            <p className="m-0 text-yellow">
              {`${userStats?.winningBattles || 0} / ${userStats?.totalBattles || 0
                }`}{" "}
              <sup>
                <ArrowUpRight size={16} />
              </sup>
            </p>
          </a>
        </div>
        <div>
          <a
            onClick={onNotificationsClick}
            href="#"
            className="notifications-icon border border-secondary"
          >
            <Bell size={24} />
            {notificationsCount > 0 && <div className="pulsating-circle"></div>}
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
