import { Sword } from "@phosphor-icons/react";
import { User } from "../../models/User";
import { UserStats } from "../../models/UserStats";
import UserBadgesLargeDisplay from "./UserBadgesLargeDisplay";
import { Badge } from "../../models/Badge";

interface PublicProfileProps {
  user: User;
  userStats?: UserStats;
  allBadges: Badge[];
}

const PublicProfile: React.FC<PublicProfileProps> = ({
  user,
  userStats,
  allBadges,
}) => {
  return (
    <>
      <div className="p-4 px-md-5 d-flex align-items-center gap-3 border-bottom">
        <div>
          <img
            width="80"
            height="80"
            className="rounded-4 img-fluid"
            crossOrigin="anonymous"
            alt={user?.firstName || ""}
            src={user?.pictureUrl || "/profile_placeholder.png"}
          />
        </div>
        <div>
          <h3 className="mb-1 fs-4 fw-medium">
            <small>
              {user?.firstName} {user?.lastName}
            </small>
          </h3>
          <div className="d-flex gap-3">
            <div>
              <p className="m-0 text-secondary">
                <small>Battles won</small>
              </p>
              <div className="d-flex gap-2 align-items-center text-yellow">
                <Sword size={18} />
                <p className="m-0">
                  {`${userStats?.winningBattles || 0} / ${
                    userStats?.totalBattles || 0
                  }`}{" "}
                </p>
              </div>
            </div>
            {/* <div>
              <p className="m-0 text-secondary">
                <small>Tournaments won</small>
              </p>
              <div className="d-flex gap-2 align-items-center text-yellow">
                <Trophy size={18} />
                <p className="m-0">11 / 11</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Show large badges if user has any  */}
      <div className="p-4 px-md-5 w-100">
        <div className="row">
          <UserBadgesLargeDisplay
            userBadges={userStats?.badges}
            publicProfile={true}
            allBadges={allBadges}
          />
        </div>
      </div>
    </>
  );
};

export default PublicProfile;
