import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BattleTabType } from "../utility/BattleTabType";

interface Props {
  onTabChange: (tab: BattleTabType) => void;
  selectedTab: BattleTabType;
}

const BattleTabs: React.FC<Props> = ({ onTabChange, selectedTab }) => {
  const location = useLocation(); // Get the current location to reflect tab selection

  return (
    <div className="d-flex d-lg-inline-flex justify-content-between flex-wrap gap-2">
      <button
        type="button"
        className={` ${selectedTab === "ACTIVE"
          ? "col col-lg-auto btn btn-light rounded-pill"
          : "col col-lg-auto btn btn-outline-light rounded-pill"
          } `}
        onClick={() => onTabChange("ACTIVE")} // Trigger tab change
      >
        Active battles
      </button>
      <button
        type="button"
        className={` ${selectedTab === "FINISHED"
          ? "col col-lg-auto btn btn-light rounded-pill"
          : "col col-lg-auto btn btn-outline-light rounded-pill"
          } `}
        onClick={() => onTabChange("FINISHED")} // Trigger tab change
      >
        Finished battles
      </button>
      {/* <button
            type="button"
            className={` ${selectedTab === "FIND_OPPONENTS"
              ? "col btn btn-light rounded-pill"
              : "col btn btn-outline-light rounded-pill"
              } `}
            onClick={() => onTabChange("FIND_OPPONENTS")} // Trigger tab change
          >
            Find opponents
          </button> */}
    </div>
  );
};

export default BattleTabs;
