import { Badge } from "../../models/Badge";
import { LockSimple } from "@phosphor-icons/react";
import { getBadgeImage, getBadgeName } from "../../utility/BadgeUtility";

interface UserBadgesLargeDisplayProps {
  userBadges?: Badge[];
  publicProfile?: boolean;
  allBadges: Badge[];
}

const UserBadgesLargeDisplay: React.FC<UserBadgesLargeDisplayProps> = ({
  userBadges = [],
  publicProfile = false,
  allBadges,
}) => {
  return (
    <>
      {userBadges.map((badge, index) => (
        <div
          className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4"
          key={`large-display-${badge.id || index}`}
        >
          <div className="bg-dark-light rounded-4 p-1 d-flex gap-3 h-100">
            <div className="position-relative d-flex col-4 col-md-5">
              <div className="d-flex border rounded-4 overflow-hidden align-items-center justify-content-center">
                <img
                  src={getBadgeImage(badge.id, allBadges)}
                  alt={getBadgeName(badge.id, allBadges)}
                  className="img-fluid ratio ratio-1x1"
                />
              </div>
              {badge.obtained || publicProfile ? (
                <div className="position-absolute bottom-0 left-0 p-2 d-flex">
                  <p className="m-0 text-warning bg-blur px-3 py-1 rounded-3">
                    <small>{badge.count}</small>
                  </p>
                </div>
              ) : (
                <div className="position-absolute top-0 right-0 bottom-0 left-0 rounded-4 overflow-hidden h-100 w-100 flex-grow-1 border">
                  <p className="m-0 text-warning bg-semi-transparent px-3 py-1 rounded-3 h-100 w-100 d-flex align-items-center justify-content-center">
                    <LockSimple size={20} />
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex flex-column h-100 justify-content-between py-2">
              <div>
                <h3 className="fs-6 m-0 fw-medium text-warning">
                  {badge.name}
                </h3>
                <p className="m-0 lh-sm mb-2">
                  <small>{badge.description}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default UserBadgesLargeDisplay;
