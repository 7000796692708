import { FunctionComponent, useMemo, type CSSProperties } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { BookmarkSimple, ArrowRight, Dot } from "@phosphor-icons/react";

export type LearnSongsCardType = {
  id: string
  artistName?: string;
  songTitle?: string;
  level?: string;
  subLevel?: string
  videoUrl?: string;
  thumbnailUrl?: string;
  pdfUrl?: string;
  tips?: string;
  backingTrack?: string;
  isUserLesson?: boolean;
  hasUserVideo?: boolean;
  hasUserBattle?: boolean;
  action?: () => void;
};

const LearnSongsCard: FunctionComponent<LearnSongsCardType> = ({
  artistName,
  songTitle,
  level,
  subLevel,
  thumbnailUrl,
  isUserLesson,
  hasUserVideo,
  hasUserBattle,
  action
}) => {

  const buttonText = useMemo(() => {
    if (hasUserBattle) {
      //   return "Go to my battles";
      // } else if (hasUserVideo) {
      //   return "Try again";
      // } else if (isUserLesson) {
      //   return "Upload video";
      // } else {
      //   return "Learn this song";
      // }
      return "Learn this song";
    } else if (hasUserVideo) {
      return "Learn this song";
    } else if (isUserLesson) {
      return "Learn this song";
    } else {
      return "Learn this song";
    }
  }, [isUserLesson, hasUserVideo, hasUserBattle]);

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
      <div className="" role="button" onClick={action}>
        <div className="song-image rounded-4 overflow-hidden position-relative border">
          <img crossOrigin="anonymous" src={thumbnailUrl} alt="" className="img-fluid" style={{ transform: "scale(2)" }} />
          <div className="p-2 position-absolute top-0 start-0 d-flex justify-content-between align-items-center w-100 bg-gradient-top rounded-top-4">
            <div className="d-flex gap-2">
              {level ? (
                <span className="mh-26 d-flex justify-content-center rounded-3 px-2 py-2 bg-white text-black">
                  <small className="gap-2 d-flex align-items-center text-capitalize">{level + ' '}</small></span>
                /* ends here  */
              ) : null}
              <span className="mh-26 d-flex justify-content-center rounded-3 px-2 py-1 bg-white text-black">
                <small className="gap-2 d-flex align-items-center text-capitalize">{subLevel}</small></span>
            </div>
            {/* <div>
              <BookmarkSimple size={24} />
            </div> */}
          </div>
          <div className="position-absolute bottom-0 start-0 w-100 p-2 bg-gradient-bottom justify-content-end rounded-bottom-4 d-flex">
            <img src="/images/icons/gbc-tabs-thumbnail.svg" alt="" style={{ width: "48px", height: "48px" }} />
          </div>
        </div>
        <div className="pt-3 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-1 ">
            <p className="m-0 fw-semibold text-truncate">
              <small>{artistName}</small>
            </p>
            <Dot size={12} />
            <p className="m-0 text-secondary text-truncate">
              <small>{songTitle}</small>
            </p>
          </div>
          <div className="text-yellow">
            <ArrowRight size={24} />
          </div>
        </div>
      </div>
    </div >
  );
};

export default LearnSongsCard;
