import React, { useState, useEffect } from "react";
import { Level } from "../../models/Level";
import { useAuth } from "../../providers/AuthProvider";
import { getLevels } from "../../api/levels";
import StaticLevels from "../../utility/Constants";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { X } from "@phosphor-icons/react";

interface LevelSelectorProps {
  onLevelClick: (level: Level) => void;
  onSubLevelClick: (subLevel: string) => void;
  onSubLevelCloseClick: () => void;
  currentLevel: string;
  currentSubLevel: string;
  searchItems: any[];
  onSearch: (item: any) => void;
  onClear: () => void;
}

const LevelSelector: React.FC<LevelSelectorProps> = ({
  onLevelClick,
  onSubLevelClick,
  onSubLevelCloseClick,
  currentLevel,
  currentSubLevel,
  searchItems,
  onSearch,
  onClear,
}) => {
  const [levels, setLevels] = useState<Level[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<Level | null>(null);
  const [selectedSubLevel, setSelectedSubLevel] = useState<string>("all");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { authState } = useAuth();

  // Fetch levels on component mount
  useEffect(() => {
    getLevels(authState.tokens?.access_token ?? "")
      .then((fetchedLevels) => {
        setLevels(fetchedLevels);
      })
      .catch(() => {
        // Optionally handle errors here
      });
  }, [authState.tokens?.access_token]);

  // Set initial levels to StaticLevels if levels is empty
  useEffect(() => {
    if (levels.length === 0) {
      setLevels(StaticLevels);
    }
  }, [levels]);

  // Update selectedLevel and selectedSubLevel based on currentLevel and currentSubLevel props
  useEffect(() => {
    if (levels.length > 0) {
      const level = levels.find((level) => level.id === currentLevel) || null;
      setSelectedLevel(level);
      setSelectedSubLevel(currentSubLevel);
      if (currentSubLevel !== "all") {
        setIsOpen(!!level); // Open if level is valid
      }
    }
  }, [levels, currentLevel, currentSubLevel]);

  const handleLevelClick = (level: Level) => {
    if (
      selectedLevel != level ||
      (selectedLevel == level && selectedSubLevel !== "all")
    ) {
      setSelectedLevel(level);
      setSelectedSubLevel("all");
      localStorage.setItem("selectedLevel", level.id);
      onLevelClick(level);
    }
    setIsOpen(true); // Open the level selector
  };

  const handleSubLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subLevel = e.target.value;
    setSelectedSubLevel(subLevel);
    onSubLevelClick(subLevel);
  };

  const handleCloseClick = () => {
    setIsOpen(false); // Close the level selector
    if (selectedSubLevel !== "all") {
      onSubLevelCloseClick(); // Trigger close callback
    }
  };

  const handleReopenClick = () => {
    setIsOpen(true); // Re-open the level selector
  };

  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScroll(window.scrollY > 275);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup event listener on component unmount
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div
      className="d-flex flex-column d-lg-inline-flex"
    // className={
    //   scroll
    //     ? "scrolled bg-yellow border-bottom sticky-top-custom"
    //     : "not-scrolled bg-yellow border-bottom sticky-top-custom"
    // }
    >
      <div className="d-flex d-lg-inline-flex justify-content-between flex-wrap gap-2">
        <button
          className="btn btn-light rounded-pill p-0 px-2 justify-content-center align-items-center"
          onClick={handleCloseClick}
          style={{ width: "38px", height: "38px", display: isOpen ? "flex" : "none" }}
        >
          <X size={24} />
        </button>
        <div className="d-flex col justify-content-between flex-wrap gap-2">
          {isOpen ? (
            // When the selector is open, show only the selected level and its sub-levels
            selectedLevel && (
              <>
                <button className="btn btn-light rounded-pill" disabled>
                  {selectedLevel.name}
                </button>
                <select
                  className="form-select category-levels rounded-pill w-auto col"
                  value={selectedSubLevel}
                  onChange={handleSubLevelChange}
                >
                  <option value="all">All levels</option>
                  {selectedLevel.subLevels.map((subLevel, index) => (
                    <option key={index} value={subLevel}>
                      {subLevel}
                    </option>
                  ))}
                </select>
              </>
            )
          ) : (
            <>
              {levels &&
                levels.map((level) => (
                  <button
                    className={
                      selectedLevel?.id === level.id
                        ? "col col-lg-auto btn btn-light rounded-pill"
                        : "col col-lg-auto btn btn-outline-light rounded-pill"
                    }
                    onClick={() => handleLevelClick(level)}
                  >
                    {level.name}
                  </button>
                ))}
            </>
          )}
        </div>
      </div>
      <div className="d-flex d-lg-inline-flex my-4 search-lessons">
        <ReactSearchAutocomplete styling={{ zIndex: 1000 }}
          items={searchItems}
          onSelect={onSearch}
          onClear={onClear}
          placeholder="Search artist or song"
        />
      </div>
    </div >
  );
};

export default LevelSelector;
