import React from "react";
import { Notification } from "../../models/Notification";
import { Trash, Eye } from "@phosphor-icons/react";

type NotificationCardProps = {
  notification: Notification;
  onReadClick?: () => void;
  onDeleteClick: (id: number) => void;
};

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  onReadClick,
  onDeleteClick,
}) => {
  return (
    <div
      className={`rounded-4 p-3 h-100 ${
        notification.isRead ? "bg-black" : "bg-dark-light"
      }`}
    >
      <div className="d-flex flex-column h-100 justify-content-between">
        <div>
          <span>
            <small className="text-secondary">
              {new Date(notification.createdAt).toLocaleString()}
            </small>
          </span>
          <h3 className="card-title m-0">{notification.title}</h3>
          <p className="card-text m-0">{notification.message}</p>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            onClick={onReadClick}
            className="bg-transparent border-0 rounded-0 px-0 text-yellow"
          >
            <small className="d-flex gap-2 align-items-center">
              Read message
              <Eye size={20} />
            </small>
          </button>
          {!notification.isRead && (
            <button
              className="bg-transparent border-0 text-secondary px-0"
              onClick={() => onDeleteClick(notification.id)}
            >
              <small className="d-flex gap-2 align-items-center">
                Delete
                <Trash size={20} />
              </small>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
