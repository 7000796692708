import { Badge } from "../models/Badge";

export const getBadgeImage = (badgeId: number, allBadges: Badge[]): string => {
  const badge = allBadges.find((b) => b.id === badgeId);
  return badge && badge.imageUrl?.trim()
    ? badge.imageUrl
    : "/images/badges/badge-axe-boss.png";
};

export const getBadgeName = (badgeId: number, allBadges: Badge[]): string => {
  const badge = allBadges.find((b) => b.id === badgeId);
  return badge && badge.name?.trim() ? badge.name : "Unknown Badge";
};
