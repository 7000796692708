import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import api from "../../api/config/apiConfig";
import axios, { AxiosError } from "axios";
import { useAuth } from "../../providers/AuthProvider";
import {
  setVideoInDB,
  getVideoFromDB,
  clearOldVideos,
} from "../../services/IndexedDB";

type VideoPlayerProps = {
  videoUrl: string;
  thumbnailUrl?: string;
  isPrivate: boolean; // Indicates whether the video URL requires authorization
  autoplay?: boolean; // Optional prop to control autoplay
};

const MAX_VIDEO_SIZE_BYTES = 700 * 1024 * 1024; // 700 MB
const MAX_CACHE_ITEMS = 50;

const AWSVideoPlayerForm: FunctionComponent<VideoPlayerProps> = ({
  videoUrl,
  thumbnailUrl,
  isPrivate,
  autoplay = false, // Default to false if not provided
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [videoType, setVideoType] = useState<string>("mp4");
  const [videoNotFound, setVideoNotFound] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading
  const { tokens } = useAuth()?.authState || { tokens: undefined };

  useEffect(() => {
    const fetchVideoBlob = async () => {
      setLoading(true); // Set loading to true when starting to fetch the video

      // Try to get the video from IndexedDB first
      // const cachedVideoBlob = await getVideoFromDB(videoUrl);
      // console.log("Using cached video blob for " + videoUrl);
      // if (cachedVideoBlob) {
      //   setVideoBlob(cachedVideoBlob);
      //   setLoading(false); // Video has been loaded
      //   return;
      // }
      setVideoType(videoUrl.split(".").pop() || "webm");

      try {
        const headers: Record<string, string> = {
          Range: `bytes=0-${MAX_VIDEO_SIZE_BYTES - 1}`,
        };

        if (isPrivate) {
          headers.Authorization = `Bearer ${tokens?.access_token}`;
        }

        // console.log("Load video url " + videoUrl + " is private " + isPrivate);
        console.log(headers);

        const response = await api.get(
          isPrivate ? `/videos/video-stream` : `/videos/video-stream-public`,
          {
            responseType: "blob",
            headers: headers,
            params: { filePath: `${videoUrl}` },
            timeout: 0, // Disable the timeout for this request
          }
        );
        console.log("Fetched video blob for " + videoUrl);

        setVideoBlob(response.data);
        setLoading(false); // Video has been loaded

        // Save the video to IndexedDB
        // await setVideoInDB(videoUrl, response.data);

        // Clear old videos if necessary
        // await clearOldVideos(MAX_CACHE_ITEMS);
      } catch (error: any) {
        console.error("Error fetching video URL:", error);
        setLoading(false); // Loading failed
        if ((error as AxiosError).response?.status === 404) {
          setVideoNotFound(true);
        }
      }
    };

    if (videoUrl) {
      fetchVideoBlob();
    }
  }, [videoUrl, tokens?.access_token]);

  useEffect(() => {
    console.log("Video blob changed");
    if (videoBlob && videoRef.current) {
      console.log("Video blob is not null, size:", videoBlob.size);
      const blobUrl = URL.createObjectURL(videoBlob);
      if (videoType === "webm") {
        console.log("Loading webm video");
        const videoSource1 = document.createElement("source");
        videoSource1.type = "video/" + videoType;
        videoSource1.src = blobUrl;
        videoRef.current.innerHTML = "";
        videoRef.current.appendChild(videoSource1);
      } else {
        // console.log("Loading other video");
        videoRef.current.src = blobUrl;
      }

      // Clean up the blob URL when the component unmounts or when the blob changes
      return () => URL.revokeObjectURL(blobUrl);
    }
  }, [videoBlob]);

  const handleVideoLoaded = () => {
    setLoading(false); // Video has been loaded
  };

  // Automatically pause video when it's off-screen
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current) {
          if (!entry.isIntersecting) {
            videoRef.current.pause();
          }
        }
      },
      { threshold: 0.2 } // Pause when less than 20% of the video is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div
      className="video-container lh-1 d-flex"
      style={{ position: "relative", width: "100%", height: "auto" }}
    >
      {loading && (
        <div
          className="loading-spinner"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            borderRadius: "50%",
            padding: "1rem",
            color: "white",
          }}
        >
          Loading video...
        </div>
      )}
      {!videoNotFound ? (
        <video
          ref={videoRef}
          controls
          autoPlay={autoplay}
          muted={true}
          playsInline={true}
          style={{
            width: "100%",
            height: "auto",
          }}
          controlsList="nodownload"
          poster={
            thumbnailUrl || "/images/onboarding/gbc-home-guitar-battle-2.png"
          }
          onLoadedData={handleVideoLoaded} // Hide the spinner when the video is ready to play
          onPlay={() => setLoading(false)} // Ensure the spinner is hidden when the video starts playing
        />
      ) : (
        <div style={{ padding: "1rem", textAlign: "center", color: "red" }}>
          Video not found
        </div>
      )}
    </div>
  );
};

export default React.memo(AWSVideoPlayerForm);
