import React from "react";
import { Badge } from "../../../models/Badge";
import { getBadgeImage, getBadgeName } from "../../../utility/BadgeUtility";

interface UserBadgesSmallDisplayProps {
  userBadges?: Badge[];
  allBadges: Badge[];
}

const UserBadgesSmallDisplay: React.FC<UserBadgesSmallDisplayProps> = ({
  userBadges = [],
  allBadges,
}) => {
  if (userBadges.length === 0) return null;

  const displayedBadges = userBadges.slice(0, 3); // Show up to 3 badges
  const additionalBadgeCount = userBadges.length - 3; // Calculate the "+X" count

  return (
    <>
      {displayedBadges.map((badge, index) => (
        <div key={`small-display-${badge.id || index}`}>
          <img
            key={index} // Unique key for React's list rendering
            src={getBadgeImage(badge.id, allBadges)}
            alt={getBadgeName(badge.id, allBadges)}
            className="badge-image"
          />
        </div>
      ))}
      {additionalBadgeCount > 0 && (
        <span>
          <small className="d-flex align-items-center text-secondary">
            +{additionalBadgeCount}
          </small>
        </span>
      )}
    </>
  );
};

export default UserBadgesSmallDisplay;
