import React from 'react';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import '../css/slider-style.css';
import { Helmet } from "react-helmet-async";
import { Heart, BookmarkSimple, Lightning } from "@phosphor-icons/react";
import { useAuth } from "../providers/AuthProvider";

const Home = () => {
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  const onLearnSongsTextClick = () => {
    navigate("/learn-song-list");
  };

  const onBattlesTextClick = useCallback(() => {
    navigate("/battles");
  }, [navigate]);

  const onChallengesTextClick = useCallback(() => {
    navigate("/challenges");
  }, [navigate]);

  const onLogInTextClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const onMakeMoneyTextClick = useCallback(() => {
    navigate("/make-money");
  }, [navigate]);

  return (
    <div className="d-flex flex-wrap w-100">
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Home"}</title>
      </Helmet>
      <div className="col-12 col-md-12 w-100 p-4 p-md-5 border-bottom">
        {authState.isAuthenticated ? (
          <h1 className="m-0 text-yellow fs-1 fw-semibold">You've successfully logged in.</h1>
        ) : (
          <h1 className="m-0 text-yellow fs-1 fw-semibold">Welcome to <br />Guitar Battle Club</h1>
        )}
      </div>
      <div className="d-flex flex-column justify-content-between col-12 col-md-6 p-4 p-md-5 border-bottom">
        <div>
          <h2 className="fw-semibold">Battle Arena</h2>
          <p className="m-0 text-secondary" style={{ maxWidth: "650px" }}>Challenge others to guitar battles. Invite your friends to vote for you to win the battle and earn rewards.</p>
        </div>
        <div className="mt-4 d-flex gap-4 position-relative">
          <div className="position-relative rounded-4 border">
            <img src="/images/onboarding/gbc-home-guitar-battle-1.png" alt="" className="rounded-4 d-block img-fluid" />
            <div className="p-2 p-md-3 position-absolute bottom-0 end-0">
              <span className="mh-26 d-flex justify-content-center rounded-3 px-2 py-1 bg-white text-black"><small className="gap-1 d-flex align-items-center"><Heart size={14} weight="fill" /> 3108</small></span>
            </div>
          </div>
          <div className="position-absolute right-0 bottom-0 top-0 left-0 d-flex align-items-center justify-content-center w-100 z-3">
            <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="pulsing-1" d="M120 152.026L162.09 107.25L156.716 135.907L180 145.757L137.015 192.325L143.284 160.981L120 152.026Z" fill="white" />
              <path className="pulsing-2" d="M120 152.026L162.09 107.25L156.716 135.907L180 145.757L137.015 192.325L143.284 160.981L120 152.026Z" stroke="white" stroke-width="0.2" />
            </svg>
          </div>
          <div className="position-relative rounded-4 border">
            <img src="/images/onboarding/gbc-home-guitar-battle-2.png" alt="" className="rounded-4 d-block img-fluid" />
            <div className="p-2 p-md-3 position-absolute bottom-0 end-0">
              <span className="mh-26 d-flex justify-content-center rounded-3 px-2 py-1 bg-white text-black"><small className="gap-1 d-flex align-items-center"><Heart size={14} weight="fill" /> 205</small></span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 p-4 p-md-5">
        <h2 className="fw-semibold">Guitar Lessons</h2>
        <p className="m-0 text-secondary" style={{ maxWidth: "650px" }}>You can improve your skills in the most enjoyable way possible, whether you're a beginner or an intermediate guitar player.</p>
        <div className="d-flex mt-4">
          <div className="border rounded-4 position-relative">
            <img src="/images/onboarding/gbc-home-guitar-lessons.png" alt="" className="rounded-4 d-block img-fluid" />
            <div className="p-2 p-md-3 position-absolute top-0 start-0 d-flex justify-content-between align-items-center w-100 bg-gradient-top rounded-top-4">
              <div className="d-flex gap-2">
                <span className="d-flex justify-content-center rounded-3 px-2 py-2 bg-white text-black"><small className="gap-2 d-flex align-items-center">Beginner</small></span>
                <span className="d-flex justify-content-center rounded-3 px-2 py-1 bg-white text-black"><small className="gap-2 d-flex align-items-center">Level 1</small></span>
              </div>
              <div>
                <BookmarkSimple size={24} weight="fill" />
              </div>
            </div>
            <div className="position-absolute bottom-0 start-0 w-100 p-2 p-md-3 bg-gradient-bottom justify-content-end rounded-bottom-4 d-flex">
              <img src="/images/icons/gbc-tabs-thumbnail.svg" alt="" />
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Home;

