import api, { apiBaseUrl } from "./config/apiConfig";
import { Badge } from "../models/Badge";

export const getBadges = async (): Promise<Badge[]> => {
  try {
    const response = await api.get(`${apiBaseUrl}/badges`);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch badges");
    }
  } catch (error) {
    console.error("Failed to fetch badges:", error);
    return [];
  }
};
