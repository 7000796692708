import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams
import { useAuth } from "../../providers/AuthProvider";
import {
  acceptChallenge,
  cancelChallenge,
  deleteChallenge,
  getMyChallengesSent,
  getMyChallengesReceived,
} from "../../api/challenges";
import ChallengeCard from "./ChallengeCard";
import { Helmet } from "react-helmet-async";
import SuccessModal from "../SuccessModal";
import { useBadges } from "../../providers/BadgesProvider";

const MyChallenges: FunctionComponent = () => {
  const navigate = useNavigate();
  const { badges } = useBadges();
  const { tab } = useParams<{ tab?: string }>(); // Use useParams to get tab
  const { authState } = useAuth();
  const { tokens } = authState;
  const [challengesSent, setChallengesSent] = useState<any[]>([]);
  const [challengesReceived, setChallengesReceived] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [selectedTab, setSelectedTab] = useState("RECEIVED"); // Default to RECEIVED
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Set selected tab based on URL parameter
  useEffect(() => {
    if (tab === "received") {
      setSelectedTab("RECEIVED");
    } else {
      setSelectedTab("SENT"); // Default to SENT if tab is undefined or "sent"
    }
  }, [tab]);

  const fetchChallenges = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const sentChallenges: any[] = await getMyChallengesSent(accessToken);
      const receivedChallenges: any[] = await getMyChallengesReceived(
        accessToken
      );
      setChallengesSent(sentChallenges);
      setChallengesReceived(receivedChallenges);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(
        error instanceof Error ? error : new Error("Failed to fetch challenges")
      );
    }
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    navigate(`/my-challenges/${tab.toLowerCase()}`);
  };

  const onCancelChallengeClick = async (battleId: number) => {
    try {
      const accessToken = tokens?.access_token || "";
      await cancelChallenge(accessToken, battleId);
      fetchChallenges();

      // Show success modal
      //TODO Display how many points were refunded?
      setSuccessMessage("The challenge has been successfully canceled.");
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Failed to cancel challenge", error);
    }
  };

  const onAcceptChallengeClick = async (battleId: number) => {
    try {
      const accessToken = tokens?.access_token || "";
      await acceptChallenge(accessToken, battleId);
      fetchChallenges();

      // Show success modal
      setSuccessMessage("The challenge has been successfully accepted!");
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Failed to accept challenge", error);
    }
  };

  const onDeleteChallengeClick = async (battleId: number) => {
    const accessToken = tokens?.access_token || "";
    await deleteChallenge(accessToken, battleId);
    fetchChallenges();
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"My challenges"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <div className="text-lg-center">
          <h2 className="fw-semibold fs-1">Your Challenges</h2>
          <p className="mb-4 text-secondary">
            View and manage all your sent and received challenges in one place.
          </p>
          <div className="d-flex d-lg-inline-flex justify-content-between flex-wrap gap-2">
            <button
              className={` ${
                selectedTab === "RECEIVED"
                  ? "col btn btn-light rounded-pill d-block d-lg-flex"
                  : "col btn btn-outline-light rounded-pill d-block d-lg-flex"
              }`}
              onClick={() => handleTabChange("RECEIVED")}
            >
              Received{" "}
              <span className="d-none d-lg-inline-block">Challenges</span>
            </button>
            <button
              className={` ${
                selectedTab === "SENT"
                  ? "col btn btn-light rounded-pill d-block d-lg-flex"
                  : "col btn btn-outline-light rounded-pill d-block d-lg-flex"
              }`}
              onClick={() => handleTabChange("SENT")}
            >
              Sent <span className="d-none d-lg-inline-block">Challenges</span>
            </button>
          </div>
        </div>
      </div>
      <div className="p-4 px-md-5 w-100">
        {loading && (
          <div className="p-4 px-md-5 text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {selectedTab === "SENT" && challengesSent.length === 0 && (
          <div className="p-4 px-md-5 text-center">
            <p>No sent challenges yet.</p>
          </div>
        )}
        {selectedTab === "RECEIVED" && challengesReceived.length === 0 && (
          <div className="p-4 px-md-5 text-center">
            <p>No received challenges yet.</p>
          </div>
        )}
        <div className="row">
          {selectedTab === "SENT" &&
            challengesSent.map((sentChallenge) => (
              <ChallengeCard
                key={sentChallenge.id}
                challenge={sentChallenge}
                type="SENT"
                allBadges={badges}
                onCancelChallengeClick={onCancelChallengeClick}
                onAcceptChallengeClick={() => {}}
                onDeleteChallengeClick={onDeleteChallengeClick}
              />
            ))}
          {selectedTab === "RECEIVED" &&
            challengesReceived.map((receivedChallenge) => (
              <ChallengeCard
                key={receivedChallenge.id}
                challenge={receivedChallenge}
                type="RECEIVED"
                allBadges={badges}
                onCancelChallengeClick={onCancelChallengeClick}
                onAcceptChallengeClick={onAcceptChallengeClick}
                onDeleteChallengeClick={onDeleteChallengeClick}
              />
            ))}
        </div>
      </div>
      {/* Success Modal */}
      <SuccessModal
        title="Challenge"
        text={successMessage}
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
      />
    </>
  );
};

export default MyChallenges;
