import React, { FC, useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { loginEmail, loginFacebook, loginGoogle } from "../../api/login";
import { useAuth } from "../../providers/AuthProvider";
// import FacebookLogin, {
//   ProfileSuccessResponse,
// } from "@greatsumini/react-facebook-login";
import SignInWithEmailForm from "./SignInWithEmailForm";
import ForgotPasswordContainer from "../reset_password/ForgotPasswordContainer";
import { logger as Logger, LogLevel } from "../../services/Logger";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Disable } from 'react-disable';

interface SignInProps { }

const SignIn: FC<SignInProps> = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [loginInProgress, setLoginInProgress] = useState(false);

  // const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID as string;

  const handleGoogleLogin = async (code: string) => {
    try {
      setLoginInProgress(true);
      const { accessToken, refreshToken, user, lessons } = await loginGoogle(
        code
      );

      Logger.log(LogLevel.INFO, "Successful Google login:", {
        accessToken,
        refreshToken,
        user,
        lessons,
      });


      setLoginInProgress(false);
      // Use the login method from AuthProvider to update authentication state
      if (accessToken && user) {
        login(user, {
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        if (lessons && lessons.length > 0) {
          navigate("/home");
        } else {
          navigate("/home");
        }
      } else {
        Logger.error("Google login failed: Access token or user not available");
      }
    } catch (error: any) {
      setLoginInProgress(false);
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = (responseData as { message: string }).message;
        console.error("Request failed:", errorMessage);
        setError(errorMessage);
      } else {
        console.error("Sign up failed:", error);
      }
    }
  };

  const onClickLoginGoogle = useGoogleLogin({
    onError: (error) => {
      Logger.error("Google onError:", error);
    },
    onSuccess: (codeResponse) => {
      // console.log(codeResponse);
      handleGoogleLogin(codeResponse.code);
    },
    flow: "auth-code",
    scope: "openid email profile",
    // ux_mode: "redirect",
    // redirect_uri: `${window.location.origin}/sign-in`,
  });

  // const onClickFacebook = async (response: ProfileSuccessResponse) => {
  //   try {
  //     const { accessToken, refreshToken, user } = await loginFacebook(response);

  //     if (accessToken && user) {
  //       login(user, { access_token: accessToken, refresh_token: refreshToken });
  //       navigate("/my-lessons");
  //     } else {
  //       Logger.error(
  //         "Facebook login failed: Access token or user not available"
  //       );
  //     }
  //   } catch (error) {
  //     Logger.error("Facebook login failed:", error);
  //   }
  // };

  const onClickSignInWithEmail = async (data: {
    email: string;
    password: string;
  }) => {
    try {
      setLoginInProgress(true);
      const { accessToken, user, lessons } = await loginEmail(
        data.email,
        data.password
      );
      login(user, { access_token: accessToken, refresh_token: "" });
      setLoginInProgress(false);
      if (lessons && lessons.length > 0) {
        navigate("/home");
      } else {
        navigate("/home");
      }
    } catch (error) {
      setLoginInProgress(false);
      Logger.error("Email sign in failed:", error);
      setLoginFailed(true);
    }
  };

  const onForgotPasswordClick = () => {
    setShowForgotPassword(true); // Set the state to show the ForgotPassword component
  };

  const handleForgotPasswordClose = () => {
    setShowForgotPassword(false); // Close the ForgotPassword component
  };

  const onSignUpTextClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  return (
    <div className="w-100">
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"Sign in"}</title>
      </Helmet>
      <Disable disabled={loginInProgress}>
        <div className="d-flex flex-column w-100">

          {(loginInProgress &&
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Login in progress</span>
              </div>
            </div>
          )}
          <div className="d-flex flex-column">
            {/* Warning */}
            {error && <div style={{ color: "yellow" }}>{error}</div>}
            {showForgotPassword ? (
              <div className="p-4 p-md-5 border-bottom">
                <ForgotPasswordContainer
                  onClose={handleForgotPasswordClose}
                />
              </div>
            ) : (
              <>
                <div className="border-bottom p-4 p-md-5">
                  <p className="mb-0 text-secondary text-center">Welcome!</p>
                  <h1 className="m-0 fs-1 fw-semibold text-center">Log in or sign up</h1>
                  {loginFailed && (
                    <div className="text-warning text-center px-4 px-md-5 py-2 py-md-4">
                      <p className="mb-0"><small>Login failed. Please try again.</small></p>
                    </div>
                  )}
                </div>

                <div className="p-4 px-md-5 pb-0 mx-auto" style={{ maxWidth: "460px", width: "100%" }}>
                  <SignInWithEmailForm
                    continue={onClickSignInWithEmail}
                    onForgotPasswordClick={onForgotPasswordClick}
                    clearErrorMessage={() => setLoginFailed(false)}
                  />
                </div>
                <div className="p-4 px-md-5 pt-0 pb-3 mx-auto" style={{ maxWidth: "460px", width: "100%" }}>
                  <div className="d-flex gap-4 align-items-center">
                    <div className="col"><hr /></div>
                    <div className="text-center"><p className="m-0">or</p></div>
                    <div className="col"><hr /></div>
                  </div>
                  <button
                    className="btn bg-dark-light rounded-pill mx-auto my-4 px-2 py-2 d-flex gap-3 align-items-center justify-content-between"
                    onClick={onClickLoginGoogle}
                  >
                    <span>
                      <img src="/images/icons/icon-google-color.svg" alt="" className="" />
                    </span>
                    Log in with{" "} Google
                    <span style={{ width: "32px" }}>&nbsp;</span>
                  </button>
                  {/* <FacebookLogin
                        appId="6022926104498630"
                        fields="first_name,last_name,email,picture"
                        onSuccess={(response) => {
                          console.log("Login Success!", response);
                        }}
                        onFail={(error) => {
                          console.log("Login Failed!", error);
                        }}
                        onProfileSuccess={(response) => {
                          console.log("Get Profile Success!", response);
                          onClickFacebook(response);
                        }}
                        render={({ onClick, logout }) => (
                          <button className="btn bg-dark-light rounded-pill w-100 mx-auto my-4 px-2 py-2 mt-0 d-flex gap-3 align-items-center justify-content-between" onClick={onClick}>
                            <span>
                              <img src="/images/icons/icon-facebook-color.svg" alt="" className="" />
                            </span>
                            Log in with{" "} Facebook
                            <span style={{ width: "32px" }}>&nbsp;</span>
                          </button>
                        )}
                      /> */}
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-column border-top">
            <div className="p-4 p-md-5 text-center">
              <p className="">
                Not a member yet?{" "}
              </p>
              <button className="btn btn-warning rounded-pill px-3 py-2" onClick={onSignUpTextClick}>
                Create an account
              </button>
            </div>
          </div>
        </div>
      </Disable >
    </div>
  );
};

export default SignIn;
