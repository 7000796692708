import api from "./config/apiConfig";
import { Battle } from "../models/Battle";

export const getMyBattles = async (
  accessToken: string,
  status: string
): Promise<Battle[]> => {
  try {
    const response = await api.get(`battles/my-list?status=${status}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get my battles failed");
    }
  } catch (error) {
    console.error("Get my battles failed:", error);
    throw error;
  }
};

// export const getAllBattles = async (status: string): Promise<Battle[]> => {
//   try {
//     const response = await api.get(`battles/list?status=${status}`);

//     if (response.status === 200) {
//       return response.data;
//     } else {
//       throw new Error("Get all battles failed");
//     }
//   } catch (error) {
//     console.error("Get all battles failed:", error);
//     throw error;
//   }
// };

export const getAllBattles = async (
  status: string,
  page: number,
  limit: number
): Promise<{ battles: Battle[]; hasMore: boolean; remainingPages: number }> => {
  try {
    const response = await api.get(
      `battles/list?status=${status}&page=${page}&limit=${limit}`
    );

    if (response.status === 200) {
      return {
        battles: response.data.battles,
        hasMore: response.data.hasMore,
        remainingPages: response.data.remainingPages,
      };
    } else {
      throw new Error("Failed to fetch battles");
    }
  } catch (error) {
    console.error("Error fetching battles:", error);
    throw error;
  }
};

export const getSingleBattle = async (battleId: number): Promise<Battle> => {
  try {
    const response = await api.get(`/battles/list/${battleId}`);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Get battle failed");
    }
  } catch (error) {
    console.error("Get battle failed:", error);
    throw error;
  }
};

export const addLikeToBattle = async (
  battleId: number,
  player: number
): Promise<Battle> => {
  try {
    const response = await api.post(`battles/like`, {
      battleId: battleId,
      player: player,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Send like to battle failed");
    }
  } catch (error) {
    console.error("Send like to battle failed:", error);
    throw error;
  }
};
