import { useState, useEffect, ChangeEvent } from "react";
import { Advertiser, Campaign } from "../../models/Advertisment";
import { addAdvertiser, addCampaign, getAdvertisers } from "../../api/admin";

export default function AdminAdvertiserManager() {
    const [advertisers, setAdvertisers] = useState<Advertiser[]>([]);
    const [newAdvertiser, setNewAdvertiser] = useState({ name: "", link: "", logo: "" });
    const [campaignData, setCampaignData] = useState({
        advertiserId: "",
        banner: "",
        offerLink: "",
        battlesPurchased: 0,
        remainingBattles: 0,
    });

    useEffect(() => {
        getAdvertisers().then(setAdvertisers);
    }, []);

    const handleAddAdvertiser = async () => {
        if (newAdvertiser.name && newAdvertiser.link) {
            const response = await addAdvertiser({ id: "", ...newAdvertiser, campaigns: [] });
            setAdvertisers([...advertisers, {...newAdvertiser, id: response, campaigns: []}]);
            setNewAdvertiser({ name: "", link: "", logo: "" });
        }
    };

    const handleAddCampaign = async () => {
        if (campaignData.advertiserId && campaignData.offerLink) {
            const newCampaignId = await addCampaign({ ...campaignData, remainingBattles: campaignData.battlesPurchased, id: "" });
            setAdvertisers((prev) =>
                prev.map((adv) =>
                    adv.id === campaignData.advertiserId
                        ? { ...adv, campaigns: [...adv.campaigns, {...campaignData, id: newCampaignId}] }
                        : adv
                )
            );
            setCampaignData({ advertiserId: "", banner: "", offerLink: "", battlesPurchased: 0, remainingBattles: 0 });
        }
    };

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>, setState: any, key: string) => {

        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setState((prev: any) => ({ ...prev, [key]: reader.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="p-4 space-y-6">
            <div className="flex items-center justify-between">
                <div className="p-4 space-y-2">
                    <h2 className="text-xl font-bold">Add Advertiser</h2>
                    <input
                        placeholder="Advertiser Name"
                        value={newAdvertiser.name}
                        onChange={(e) => setNewAdvertiser({ ...newAdvertiser, name: e.target.value })}
                    />
                    <input
                        placeholder="Advertiser Link"
                        value={newAdvertiser.link}
                        onChange={(e) => setNewAdvertiser({ ...newAdvertiser, link: e.target.value })}
                    />
                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageUpload(e, setNewAdvertiser, "logo")} />
                    <button onClick={handleAddAdvertiser}>Add Advertiser</button>
                </div>
            </div>

            <div className="border-b-2 border-gray-200">
                <div className="p-4 space-y-2">
                    <h2 className="text-xl font-bold">Add Campaign</h2>
                    <select
                        value={campaignData.advertiserId}
                        onChange={(e) => setCampaignData({ ...campaignData, advertiserId: e.target.value })}
                        className="border p-2 rounded w-full"
                    >
                        <option value="">Select Advertiser</option>
                        {advertisers.map((adv) => (
                            <option key={adv.id} value={adv.id}>
                                {adv.name}
                            </option>
                        ))}
                    </select>
                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageUpload(e, setCampaignData, "banner")} />
                    <input
                        placeholder="Offer Link"
                        value={campaignData.offerLink}
                        onChange={(e) => setCampaignData({ ...campaignData, offerLink: e.target.value })}
                    />
                    <input
                        type="number"
                        placeholder="Battles Purchased"
                        value={campaignData.battlesPurchased}
                        onChange={(e) => setCampaignData({ ...campaignData, battlesPurchased: Number(e.target.value) })}
                    />
                    <button onClick={handleAddCampaign}>Add Campaign</button>
                </div>
            </div>

            <div className="space-y-4">
                {advertisers.map((adv) => (
                    <div key={adv.id}>
                        <div className="p-4">
                            <h3 className="text-lg font-bold">{adv.name}</h3>
                            <p>Website: <a href={adv.link} target="_blank" className="text-blue-500">{adv.link}</a></p>
                            <h4 className="mt-2 font-semibold">Campaigns:</h4>
                            {adv.campaigns.length > 0 ? (
                                <ul className="list-disc pl-4">
                                    {adv.campaigns.map((camp) => (
                                        <li key={camp.id}>{camp.offerLink} ({camp.battlesPurchased} battles - {camp.remainingBattles} remaining)</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No campaigns yet.</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
