import React, { FC, useCallback, useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { getVideosForFeedback } from "../../api/admin";
import { LearnSongsCardType } from "../songs/LearnSongsCard";
import { UserVideoForFeedback } from "../../models/UserVideoForFeedback";
import AdminUserVideoCard from "./AdminUserVideoCard";

interface AdminProps { }

const Admin: FC<AdminProps> = () => {
  const { authState } = useAuth();
  const isAdmin = authState.user?.isAdmin;
  const navigate = useNavigate();
  const [videosforFeedback, setVideosForFeedback] = useState<
    UserVideoForFeedback[]
  >([]);

  useEffect(() => {
    if (isAdmin === false) {
      navigate("/home");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    let ignore = false;
    setVideosForFeedback([]);

    getVideosForFeedback()
      .then((result) => {
        if (!ignore) {
          setVideosForFeedback(result);
        }
      })
      .catch((error) => {
        console.error(
          "Error handling GET requests to /admin/videos/pending:",
          error
        );
      });
    return () => {
      ignore = true;
    };
  }, []);

  const handleChildAction = useCallback(
    (userVideo: LearnSongsCardType) => {
      navigate("/admin-video-feedback", { state: { userVideo: userVideo } });
    },
    [navigate]
  );

  // const onClickLoginGoogle = useGoogleLogin({
  //   onSuccess: async (codeResponse) => {
  //     try {
  //       const refreshToken = await validateGoogleToken(codeResponse.code);
  //       // console.log("Google login success:", refreshToken);
  //     } catch (error) {
  //       console.error("Google login failed:", error);
  //     }
  //   },
  //   flow: "auth-code",
  //   scope: "https://www.googleapis.com/auth/youtube.upload",
  // });

  // const onClickParseAwsFiles = useCallback(async () => {
  //   try {
  //     const accessToken = tokens?.access_token || "";
  //     await parseAwsFiles(accessToken);
  //   } catch (error) {
  //     console.error("Parsing files:", error);
  //     throw error;
  //   }
  // }, []);

  return (
    <>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Advertiser Manager</h2>
        <p className="m-0 text-secondary">
          Here you can add new advertisers and campaigns.
          <button
            className="btn btn-primary"
            onClick={() => navigate("/admin-advertiser-manager")}
          >
            Go to Advertiser Manager
          </button>
        </p>
      </div>
      <div className="p-4 px-md-5 w-100 border-bottom">
        <h2 className="fw-semibold fs-1">Admin Panel</h2>
        <p className="m-0 text-secondary">Here you will find the videos that you will need to rate.</p>
      </div>

      <div className="p-4 px-md-5">
        <div className="row">
          {videosforFeedback.map((userVideo, index) => {
            return (
              <AdminUserVideoCard
                key={userVideo.id}
                video={userVideo}
                action={() => handleChildAction(userVideo)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Admin;
