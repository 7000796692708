import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getMyLessons } from "../../api/lessons";
import { useAuth } from "../../providers/AuthProvider";
import { Song } from "../../models/Song";
import SongCardsContainer, {
  SongCardsContainerProps,
} from "../songs/SongCardsContainer";
import { LearnSongsCardType } from "../songs/LearnSongsCard";
import { Helmet } from "react-helmet-async";

const MyLessons: FunctionComponent = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { tokens } = authState;
  const [songLessons, setSongLessons] = useState<Song[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchLessons = async () => {
      try {
        const accessToken = tokens?.access_token || "";
        console.log("GET LESSONS " + accessToken);
        const songLessons: Song[] = await getMyLessons(accessToken);
        setSongLessons(songLessons);
        setLoading(false);
      } catch (error) {
        console.error("Add lesson failed:", error);
        setLoading(false);
      }
    };

    fetchLessons();
  }, [authState.isAuthenticated, authState.tokens?.access_token]);

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      navigate("/learn-song-play-lesson", { state: { song } });
    },
    [navigate]
  );

  const songsProps: SongCardsContainerProps = {
    songs: songLessons,
    childAction: onClickSong,
    pageCount: 0,
    currentPage: 0,
  };

  return (
    <>
      {/* Helmet component to dynamically set the page title */}
      <Helmet>
        <title>{"My lessons"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 border-bottom text-lg-center">
        <h2 className="fw-semibold fs-1">Saved Lessons</h2>
        <p className="m-0 text-secondary">Here you can manage all the songs that you chose to practice and learn.</p>
      </div>
      <div className="p-4 px-md-5">
        <SongCardsContainer
          childAction={songsProps.childAction}
          songs={songsProps.songs}
          showLevel={true}
          pageCount={0}
          currentPage={0}
        />
      </div>
    </>
  );
};

export default MyLessons;
