import React, { useEffect } from "react";
import { UserVideo } from "../../models/UserVideo";
import { formatDate } from "../../utility/DateFormatter";
import AWSVideoPlayerForm from "../components/AWSVideoPlayerForm";
import { Play, Sword, CheckCircle, Trash, Dot, ArrowRight } from "@phosphor-icons/react";
import axios from "axios";

interface MyVideoCardProps {
  id: string;
  video: UserVideo;
  isPlaying: boolean;
  isModalView: boolean;
  onPlay: () => void;
  onTryAgainClick: (songId?: string) => void;
  onFindOpponentClick: (userVideo: UserVideo) => void;
  onDelete: (video: UserVideo) => void;
  onVideoSelected: (video: UserVideo) => void;
}

const MyVideoCard: React.FC<MyVideoCardProps> = ({
  id,
  video,
  isPlaying,
  isModalView,
  onPlay,
  onTryAgainClick,
  onFindOpponentClick,
  onDelete,
  onVideoSelected,
}) => {
  const localCreatedDate = formatDate(new Date(video.createdAt));
  const [thumbnailError, setThumbnailError] = React.useState(false);

  const reviewContent =
    video.reviewState === "pending" ? (
      <div className="d-flex flex-column gap-1 lh-sm">
        <p className="fw-semibold m-0">Your video is under evaluation.</p>
        <p className="m-0 text-secondary"><small>Come back for feedback in 24 hours.</small></p>
      </div>
    ) : (
      <div className="d-flex flex-column gap-2 lh-sm w-100">
        {(video.points.map((point) => (
          <div className="d-flex w-100 justify-content-between align-items-center" key={point.id}>
            <p className="m-0"><small>{point.name}</small></p>
            <p className="m-0 text-secondary"><small>{point.points} / {point.max_points} Points</small></p>
          </div>
        )))}
        <div className="d-flex w-100 justify-content-between align-items-center">
          {video.percentage < 85 ? (
            <p className="m-0 text-warning"><small>Practice to reach 80 points <br />and join battles!</small></p>
          ) : (
            <p className="m-0 text-yellow"><small>You can now challenge others!</small></p>
          )}
          <p className="m-0 text-warning"><small>{video.percentage} Points</small></p>
        </div>
      </div>
    );

  const actionButton =
    isModalView ? (
      <button
        onClick={() => onVideoSelected(video)}
        className="btn btn-warning rounded-pill"
      >
        <small className="d-flex gap-2 align-items-center">
          Select
          <CheckCircle size={16} />
        </small>
      </button>
    ) :
      video.percentage < 85 ? (
        <button
          onClick={() => onDelete(video)}
          className="btn btn-warning rounded-pill"
        >
          <small className="d-flex gap-2 align-items-center">
            Delete
            <Trash size={16} />
          </small>
        </button>
      ) : (
        <button
          onClick={() => onFindOpponentClick(video)}
          className="btn btn-warning rounded-pill"
          disabled={video.percentage < 85}
        >
          <small className="d-flex gap-2 align-items-center">
            Go battle
            <Sword size={16} />
          </small>
        </button>
      );

  useEffect(() => {
    console.log("Thumbnail URL: ", video?.thumbnailUrl);
    if (video?.thumbnailUrl) {
      axios.get(video.thumbnailUrl)
        .then(() => {
          console.log("Thumbnail loaded successfully");
          setThumbnailError(false);
        })
        .catch(() => {
          console.log("Thumbnail failed to load");
          setThumbnailError(true);
        });
    } else {
      setThumbnailError(true);
    }
  }, [video?.thumbnailUrl]);

  return (
    <div className="d-flex flex-column h-100 pb-4">
      <div
        className="position-relative rounded-4 mb-3 overflow-hidden border align-items-center your-videos-card"
        style={{ position: "relative" }}
      >
        {isPlaying ? (
          <AWSVideoPlayerForm
            videoUrl={video.videoUrl}
            thumbnailUrl={video.thumbnailUrl}
            isPrivate={true}
            autoplay={true}
          />
        ) : (
          thumbnailError ? (
            <div className="d-flex flex-column w-100 h-100 bg-black p-5 text-white align-items-center justify-content-center text-center">
              <p className="fw-semibold"><small>Your video is being processed and will be ready in just a few minutes.</small> </p>
              <p className="text-secondary"><small>You'll be able to watch it soon, hang tight!</small></p>
            </div>
          ) :
            <>
              <img
                crossOrigin="anonymous"
                src={video?.thumbnailUrl}
                style={{ width: "100%", height: "auto" }}
              />
              <button
                onClick={onPlay}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "60px",
                  height: "60px",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  border: "none",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  zIndex: 10,
                }}
              >
                <Play size={24} />
              </button>
            </>
        )}
      </div>
      <div className="pt-3 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-1 ">
          <p className="m-0 fw-semibold text-truncate">
            <small>
              {video.song?.artistName || " "}
            </small>
          </p>
          <Dot size={12} />
          <p className="m-0 text-secondary text-truncate">
            <small>
              {video.song?.songTitle || " "}
            </small>
          </p>
        </div>
      </div>

      <div className="mt-3 d-flex flex-column justify-content-between align-items-start gap-3">
        {reviewContent}
        {actionButton}
      </div>
    </div >
  );
};

export default MyVideoCard;
