import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useAuth } from "../../providers/AuthProvider";
import UserMenu from "./UserMenu";
import QuickMenu from "./QuickMenu";
import { getNotificationsCount } from "../../api/users";

export const MainLayout = () => {
  const { authState } = useAuth();
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchNotificationsCount();
    }
  }, [authState.user, location]);

  const fetchNotificationsCount = async () => {
    try {
      const count = await getNotificationsCount();
      setNotificationsCount(count);
    } catch (error) {
      console.error("Failed to fetch notifications count:", error);
    }
  };

  return (
    <div>
      <Header />
      {authState.isAuthenticated && (
        <UserMenu
          notificationsCount={notificationsCount}
          refreshNotifications={fetchNotificationsCount}
        />
      )}
      <main className="d-flex flex-wrap w-100">
        <Outlet context={{ setNotificationsCount }} />
      </main>
      <QuickMenu />
      <Footer />
    </div>
  );
};
