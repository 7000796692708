import {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SongCardsContainer from "./SongCardsContainer";
import LevelSelector from "./LevelSelector";
import { LearnSongsCardType } from "./LearnSongsCard";
import { fetchLearnSongsFromAPI } from "../../data/videosSlice";
import { useAuth } from "../../providers/AuthProvider";
import { Level } from "../../models/Level";
import { addLesson } from "../../api/lessons";
import { Helmet } from "react-helmet-async";
import { allSongs, getSongById } from "../../api/songs";

const LearnSongsListing: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { authState } = useAuth();
  const { tokens } = authState;

  // ** Reference to the container-gbc element
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!tokens?.access_token);
  const [loading, setLoading] = useState(true);
  const [cachedSongs, setCachedSongs] = useState<{
    [levelId: string]: LearnSongsCardType[];
  }>({});
  const [filteredSongs, setFilteredSongs] = useState<LearnSongsCardType[]>([]);
  const [totalSongs, setTotalSongs] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [autoCompleteItems, setAutoCompleteItems] = useState<any[]>([]);

  const currentLevelId = searchParams.get("level") || "beginner";
  const currentSubLevel = searchParams.get("subLevel") || "all";
  const currentPage = parseInt(searchParams.get("page") || "0", 10);
  const searchValue = searchParams.get("search") || "";

  const SONGS_PER_PAGE = 12;

  useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  // Fetch autocomplete items
  useEffect(() => {
    const fetchAutoCompleteItems = async () => {
      try {
        const response = await allSongs();
        const data = response.map((song: any) => ({
          id: song.id,
          name: `${song.artistName} - ${song.songTitle} (${song.level.charAt(0).toUpperCase() + song.level.slice(1)} - ${song.subLevel})`,
          artist: song.artistName,
          title: song.songTitle,
          level: song.level,
          subLevel: song.subLevel
        }));
        setAutoCompleteItems(data);
      } catch (error) {
        console.error("Error fetching autocomplete items:", error);
      }
    };

    fetchAutoCompleteItems();
  }, []);
  // // Scroll to top when the component mounts or when the location changes (includes back/forward navigation)
  // useEffect(() => {
  //   console.log("URL changed");
  //   window.scrollTo(0, 0); // Scrolls to the top of the page

  //   const container = containerRef.current;
  //   if (container) {
  //     // container.scrollTo(0, 0); // Scrolls the container to the top
  //     container.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  //   // Ensure the container scrolls to the top
  //   // const container = containerRef.current;
  //   // if (container) {
  //   //   container.scrollTop = 0; // Reset the container's scroll position
  //   // }
  // }, [location]); // Trigger scroll on any location (URL) change

  // Only fetch songs when the level changes
  useEffect(() => {
    if (!cachedSongs[currentLevelId]) {
      getSongsFromAPI(currentLevelId); // Fetch only when not cached
    } else {
      updateFilteredSongs(currentLevelId, currentSubLevel, currentPage, searchValue);
    }
  }, [currentLevelId, currentSubLevel, currentPage, searchValue]);

  // Effect cachedSongs when it changes
  useEffect(() => {
    if (cachedSongs[currentLevelId]) {
      updateFilteredSongs(currentLevelId, currentSubLevel, currentPage, searchValue);
    }

  }, [cachedSongs]);

  // Function to fetch songs from the API when level changes
  const getSongsFromAPI = async (level: string) => {
    const accessToken = tokens?.access_token || "";
    setLoading(true); // Set loading state

    try {
      const action = await dispatch(
        fetchLearnSongsFromAPI({
          accessToken,
          level: level,
          subLevel: "all", // Fetch all sub-levels initially
          offset: 0,
          limit: 1000, // Fetch all songs at once for this level
        })
      );

      const fetchedSongs = action.payload.songs || [];
      console.log(`Fetched ${fetchedSongs.length} songs for level: ${level}`);

      // Cache the fetched songs
      setCachedSongs((prevCachedSongs) => {
        const updatedCache = {
          ...prevCachedSongs,
          [level]: fetchedSongs,
        };
        console.log("Cached songs for level:", level, fetchedSongs.length);
        return updatedCache;
      });

      // Update totalSongs and filteredSongs after caching
      setTotalSongs(fetchedSongs.length);
      // updateFilteredSongs(level, currentSubLevel, currentPage);
    } catch (error) {
      console.error("Error fetching songs:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Filter songs based on the sub-level and paginate them
  const updateFilteredSongs = (
    level: string,
    subLevel: string,
    page: number,
    searchValue?: string
  ) => {
    const songs = cachedSongs[level] || [];
    if (!songs.length) {
      console.log(`No songs found for level: ${level}`);
      setFilteredSongs([]);
      return;
    }

    let filtered = songs;

    if (subLevel !== "all") {
      filtered = filtered.filter((song) => song.subLevel === subLevel);
    }

    if (searchValue && searchValue.length > 0) {
      filtered = filtered.filter((song) => {
        return (`${song.artistName} - ${song.songTitle}`).toLowerCase().includes(searchValue.toLowerCase());
      }
      );
    }

    const start = page * SONGS_PER_PAGE;
    const end = start + SONGS_PER_PAGE;

    // console.log(
    //   `Paginating songs on page ${page} from ${start} to ${end} out of ${filtered.length}`
    // );
    if (start >= filtered.length) {
      setFilteredSongs([]);
      console.warn(`Page ${page} is out of range. No songs to display.`);
      return;
    }

    // Ensure `end` does not exceed the length of the `filtered` array
    const paginatedSongs = filtered.slice(
      start,
      Math.min(end, filtered.length)
    );

    setFilteredSongs(paginatedSongs);
    setTotalSongs(filtered.length);
  };

  const onClickSong = useCallback(
    (song: LearnSongsCardType) => {
      // Remove song.hasUserBattle from the backend if it is not used anywhere
      if (song.isUserLesson) {
        navigate("/learn-song-play-lesson", { state: { song } });
      } else {
        onAddToLesson(song);
      }
    },
    [navigate]
  );

  const onAddToLesson = async (song: LearnSongsCardType | undefined) => {
    if (song) {
      const accessToken = tokens?.access_token || "";
      try {
        await addLesson(accessToken, song.id);
        navigate("/learn-song-play-lesson", { state: { song } });
      } catch (error) {
        console.error("Error adding lesson:", error);
      }
    }
  };

  const handleLevelClick = (level: Level) => {
    setSearchParams({ level: level.id, subLevel: "all", page: "0" });
  };

  const handleSubLevelClick = (subLevel: string) => {
    setSearchParams({ level: currentLevelId, subLevel, page: "0" });
  };

  const handleSubLevelCloseClick = () => {
    setSearchParams({ level: currentLevelId, subLevel: "all", page: "0" });
  };

  const onPageChange = (data: { selected: number }) => {
    setSearchParams({
      level: currentLevelId,
      subLevel: currentSubLevel,
      page: data.selected.toString(),
    });
  };

  const onSearch = async (searchValue: any) => {
    setSearchParams({ level: searchValue.level, subLevel: searchValue.subLevel, page: "0", search: `${searchValue.artist} - ${searchValue.title}` });
  };

  return (
    <>
      <Helmet>
        <title>{"Learn songs"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 border-bottom pb-0">
        <div className="text-lg-center">
          <h2 className="fw-semibold fs-1">Guitar Lessons</h2>
          <p className="mb-4 text-secondary">You can improve your skills in the most enjoyable way possible, <br className="d-none d-lg-block" />whether you're a beginner or an intermediate guitar player.</p>
          <LevelSelector
            onLevelClick={handleLevelClick}
            onSubLevelClick={handleSubLevelClick}
            onSubLevelCloseClick={handleSubLevelCloseClick}
            currentLevel={currentLevelId}
            currentSubLevel={currentSubLevel}
            searchItems={autoCompleteItems}
            onSearch={onSearch}
            onClear={() => setSearchParams({ level: currentLevelId, subLevel: currentSubLevel, page: "0", search: "" })}
          />
        </div>
      </div>

      <div className="p-4 px-md-5">
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : filteredSongs.length > 0 ? (
          <SongCardsContainer
            childAction={onClickSong}
            songs={filteredSongs}
            pageCount={Math.ceil(totalSongs / SONGS_PER_PAGE)}
            showLevel={false}
            showPagination={true}
            onPageChange={onPageChange}
            currentPage={Math.min(
              currentPage,
              Math.max(0, Math.ceil(totalSongs / SONGS_PER_PAGE) - 1)
            )} // Ensure currentPage is within valid bounds
          />
        ) : (
          <div className="p-4 px-md-5 text-center">
            <p>No songs available for the selected level and sub-level.</p>
          </div>
        )}
      </div>
    </>
  );
};;

export default LearnSongsListing;
