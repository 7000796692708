import { X } from '@phosphor-icons/react';
import React, { ReactNode, useEffect } from "react";

interface OffcanvasProps {
  id: string;
  children: ReactNode;

  onShow?: () => void;
  onHide?: () => void;
}

const Offcanvas: React.FC<OffcanvasProps> = ({
  id,
  children,
  onShow,
  onHide,
}) => {
  useEffect(() => {
    const offcanvasElement = document.getElementById(id);

    if (!offcanvasElement) return;

    const handleShow = () => onShow && onShow();
    const handleHide = () => onHide && onHide();

    offcanvasElement.addEventListener("shown.bs.offcanvas", handleShow);
    offcanvasElement.addEventListener("hidden.bs.offcanvas", handleHide);

    return () => {
      offcanvasElement.removeEventListener("shown.bs.offcanvas", handleShow);
      offcanvasElement.removeEventListener("hidden.bs.offcanvas", handleHide);
    };
  }, [id, onShow, onHide]);

  return (
    <div
      className="offcanvas offcanvas-bottom rounded-top-4 border-0"
      tabIndex={-1}
      id={id}
      aria-labelledby={`${id}Label`}
    >
      <div className="offcanvas-header d-flex justify-content-end align-items-center p-4 px-md-5 border-bottom">
        <button
          type="button"
          className="bg-transparent border-0 p-0 text-yellow d-flex align-items-center gap-2 text-uppercase"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <span>
            <small>Close</small>
          </span>
          <X size={32} />
        </button>
      </div>
      <div className="offcanvas-body p-0">{children}</div>
    </div>
  );
};

export default Offcanvas;
