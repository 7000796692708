import React, { useEffect, useState } from "react";
import { User } from "../../../models/User";
import { UserVideo } from "../../../models/UserVideo";
import AWSVideoPlayerForm from "../../components/AWSVideoPlayerForm";
import SocialShare from "./SocialShare";
import { Dot, Heart, ShareNetwork } from "@phosphor-icons/react";
import OffcanvasButton from "../../components/OffcanvasButton";
import Offcanvas from "../../components/Offcanvas";
import UserBadgesSmallDisplay from "./UserBadgesSmallDisplay";
import { UserStats } from "../../../models/UserStats";
import BattlesSmallDisplay from "./BattlesSmallDisplay";
import { Badge } from "../../../models/Badge";

interface LargeBattleCardProps {
  playerOrder: number;
  playerData: {
    video: UserVideo;
    likes: number;
    user: User;
    userStats: UserStats;
  };
  battleStatus: string;
  isBattleLikedByMe: boolean;
  isPlayerLikedByMe: boolean;
  socialShareTitle: string;
  allBadges: Badge[];
  onLikeClick: (playerOrder: number) => void;
}

const LargeBattleCard: React.FC<LargeBattleCardProps> = ({
  playerOrder,
  playerData,
  battleStatus,
  isBattleLikedByMe,
  isPlayerLikedByMe,
  socialShareTitle,
  allBadges,
  onLikeClick,
}) => {
  const getOpponentFullName = (user: User): string => {
    const { firstName, lastName } = user;
    return `${firstName} ${lastName}`;
  };
  const [url, setUrl] = useState<string>(window.location.href);
  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <>
      <div className="rounded-4 overflow-hidden position-relative border mb-4 d-flex align-items-center h-100">
        <AWSVideoPlayerForm
          videoUrl={playerData.video?.videoUrl}
          thumbnailUrl={playerData.video?.thumbnailUrl}
          isPrivate={false}
        />
        <div className="p-2 position-absolute bottom-0 end-0">
          <span className="d-flex justify-content-center rounded-3 px-2 py-1 bg-white text-black">
            <small className="gap-1 d-flex align-items-center">
              <Heart size={14} weight="fill" /> {playerData.likes || 0}
            </small>
          </span>
        </div>
      </div>
      <div>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <div>
              <img
                width="40"
                height="40"
                className="rounded-3 img-fluid mt-1"
                crossOrigin="anonymous"
                // alt={user?.firstName || ""}
                src={playerData.user?.pictureUrl || "/profile_placeholder.png"}
              />
            </div>
            <div>
              <h3 className="mb-1 fs-6 fw-medium text-start">
                <small className="text-truncate">
                  {getOpponentFullName(playerData.user)}
                </small>
              </h3>
              <div className="user-info d-flex align-items-center gap-2 flex-wrap">
                <UserBadgesSmallDisplay
                  userBadges={playerData.userStats?.badges}
                  allBadges={allBadges}
                />
                <BattlesSmallDisplay
                  wins={playerData.userStats?.winningBattles}
                  total={playerData.userStats?.totalBattles}
                />
                {/* <span>
                    <small className="d-flex align-items-center text-yellow gap-1">
                      <Trophy size={18} /> 29 / 756
                    </small>
                  </span> */}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-1 mt-2">
            <p className="m-0 fw-semibold text-truncate">
              <small>{playerData?.video?.song?.artistName}</small>
            </p>
            <Dot size={12} />
            <p className="m-0 text-secondary text-truncate">
              <small>{playerData?.video?.song?.songTitle}</small>
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
          <OffcanvasButton
            targetId="socialShare"
            label={<small>Share</small>}
            icon={<ShareNetwork size={20} />}
            className="d-flex gap-2 align-items-center bg-transparent p-0 border-0 text-yellow"
          />
          <Offcanvas id="socialShare">
            <SocialShare url={url} title={socialShareTitle} />
          </Offcanvas>

          {isBattleLikedByMe || battleStatus === "finished" ? (
            isPlayerLikedByMe && (
              <div className="after-vote">
                <p className="mb-0 fw-medium">
                  <small>
                    Voted <Heart size={20} weight="fill" />
                  </small>
                </p>
              </div>
            )
          ) : (
            <div className="text-center vote-video-btn">
              <input
                type="checkbox"
                disabled={isBattleLikedByMe || battleStatus === "finished"}
                onChange={() => onLikeClick(playerOrder)}
                id={`like-checkbox-${playerOrder}`}
                checked={isPlayerLikedByMe}
              />
              <label htmlFor={`like-checkbox-${playerOrder}`}>
                <span>Vote</span>
                <span>
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    <Heart
                      size={20}
                      weight="fill"
                      className="heart-pulsing-1 color-black position-absolute"
                    />
                    <Heart
                      size={20}
                      weight="fill"
                      className="heart-pulsing-2 color-black"
                    />
                  </div>
                </span>
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LargeBattleCard;
