import React, { useState, useEffect } from "react";
import {
  getNotificationsByUserId,
  markNotificationAsRead,
} from "../../api/notifications";
import { useAuth } from "../../providers/AuthProvider";
import NotificationCard from "./NotificationCard";
import { Notification } from "../../models/Notification";
import { Helmet } from "react-helmet-async";
import { useNavigate, useOutletContext } from "react-router-dom";

const NotificationsPage: React.FC = () => {
  const { authState } = useAuth();
  const { tokens } = authState;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  // Get function to update notifications count
  const { setNotificationsCount } = useOutletContext<{
    setNotificationsCount: (count: number) => void;
  }>();

  useEffect(() => {
    fetchNotifications();
  }, [tokens?.access_token]);

  const fetchNotifications = async () => {
    try {
      const accessToken = tokens?.access_token || "";
      const notifications: Notification[] = await getNotificationsByUserId(
        accessToken
      );
      setNotifications(notifications);
      setNotificationsCount(notifications.length);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };

  const markAsRead = async (id: number) => {
    try {
      const accessToken = tokens?.access_token || "";
      await markNotificationAsRead(accessToken, id);

      // Update state and recalculate count based on new state
      setNotifications((prevNotifications) => {
        const updatedNotifications = prevNotifications.filter(
          (n) => n.id !== id
        );
        setNotificationsCount(updatedNotifications.length);
        return updatedNotifications;
      });
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  const handleOnReadClick = (notification: Notification) => {
    markAsRead(notification.id);
    navigate(`/notifications/${notification.id}`, {
      state: { notification },
    });
  };

  return (
    <>
      <Helmet>
        <title>{"Notifications"}</title>
      </Helmet>
      <div className="p-4 px-md-5 w-100 border-bottom text-lg-center">
        <h2 className="fw-semibold fs-1">Notifications</h2>
        <p className="m-0 text-secondary">
          Notifications will be automatically deleted after reading them.
        </p>
      </div>
      <div className="p-4 px-md-5 w-100">
        {notifications.length === 0 ? (
          <p>No notifications available.</p>
        ) : (
          <div className="row">
            {notifications.map((notification) => (
              <div
                className="col-12 col-md-6 col-lg-4 mb-4"
                key={notification.id}
              >
                <NotificationCard
                  notification={notification}
                  onReadClick={() => handleOnReadClick(notification)}
                  onDeleteClick={markAsRead}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsPage;
