import { FunctionComponent, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SingleBattleCard from "./components/SingleBattleCard";
import { getSingleBattle, addLikeToBattle } from "../../api/battles";
import { Battle } from "../../models/Battle";
import {
  generateSocialShareTitle,
  handleLikeBattle,
  checkIsBattleLikedByMe,
  checkIsPlayerLikedByMe,
} from "./utility/BattlesUtility";
import { useBadges } from "../../providers/BadgesProvider";

const SingleBattlePage: FunctionComponent = () => {
  const { badges } = useBadges();
  const { id } = useParams<{ id: string }>();
  const battleId = Number(id);
  const isBattleLikedByMe = checkIsBattleLikedByMe(battleId);
  const [battle, setBattle] = useState<Battle | null>(null);
  const [loading, setLoading] = useState(true);

  const url = `${window.location.origin}${location.pathname}`;

  const fetchSingleBattle = async (battleId: number) => {
    try {
      const result = await getSingleBattle(battleId);
      setBattle(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleBattle(battleId);
  }, [battleId]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 275);
    });
  }, []);

  const onLikeClick = async (playerOrder: number) => {
    setLoading(true);
    try {
      if (!isBattleLikedByMe && battle !== null) {
        const result = await addLikeToBattle(battle.id, playerOrder);
        setBattle(result);
        handleLikeBattle(battleId, playerOrder);
      }
      setLoading(false);
    } catch (error) {
      console.error("Sending like failed:", error);
      setLoading(false);
    }
  };

  function convertLocalDateToUTCIgnoringTimezone(date: Date) {
    const timestamp = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
        new Date().getUTCMilliseconds()
      )
    );

    return new Date(timestamp);
  }

  function calculateTimeLeftText(endDate: Date): string | null {
    if (!endDate) {
      return "";
    }
    // Set the time to the end of the day (23:59:59.999)
    const endOfDay = new Date(endDate);
    endOfDay.setHours(23, 59, 59, 999);

    const difference =
      endOfDay.getTime() -
      convertLocalDateToUTCIgnoringTimezone(new Date()).getTime();
    if (difference <= 0) {
      return "Voting has ended.";
    }

    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `Voting ends in ${days} day${days > 1 ? "s" : ""}.`;
    } else if (hours > 0) {
      return `Voting ends in ${hours} hour${hours > 1 ? "s" : ""}.`;
    } else {
      return `Voting ends in ${minutes} minute${minutes > 1 ? "s" : ""}.`;
    }
  }

  return (
    <>
      {battle && (
        <>
          <div className="p-4 px-md-5 w-100 border-bottom text-lg-center">
            <h2 className="fw-semibold fs-1">Watch Vote &amp; Share</h2>
            <p className="m-0 text-secondary">
              {battle.status === "ongoing"
                ? calculateTimeLeftText(battle.endedAt)
                : "Voting has ended."}
            </p>
          </div>
          <div className="d-flex p-4 px-md-5 gap-3 gap-md-5">
            <div className="col min-h-100 justify-content-between d-flex flex-column">
              <SingleBattleCard
                playerOrder={1}
                playerData={battle.startingOpponent}
                battleStatus={battle.status}
                isBattleLikedByMe={isBattleLikedByMe}
                isPlayerLikedByMe={checkIsPlayerLikedByMe(battle.id, 1)}
                socialShareTitle={generateSocialShareTitle(battle)}
                allBadges={badges}
                onLikeClick={onLikeClick}
              />
            </div>
            <div className="col min-h-100 justify-content-between d-flex flex-column">
              <SingleBattleCard
                playerOrder={2}
                playerData={battle.opponent}
                battleStatus={battle.status}
                isBattleLikedByMe={isBattleLikedByMe}
                isPlayerLikedByMe={checkIsPlayerLikedByMe(battle.id, 2)}
                socialShareTitle={generateSocialShareTitle(battle)}
                allBadges={badges}
                onLikeClick={onLikeClick}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SingleBattlePage;
