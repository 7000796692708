import api from "./config/apiConfig"
import { UserVideoForFeedback } from "../models/UserVideoForFeedback";
import { Point } from "../models/PointCategory";
import { Advertiser, Campaign } from "../models/Advertisment";

export const getVideosForFeedback = async (): Promise<
  UserVideoForFeedback[]
> => {
  try {
    const response = await api.get("/admin/videos/pending", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Get videos for feedback failed:", response);
      return [];
    }
  } catch (error) {
    console.error("Get videos for feedback failed:", error);
    return [];
  }
};

export const submitFeedback = async (
  videoId: string,
  points: Point[]
): Promise<void> => {
  try {
    const response = await api.post("/admin/feedback", {
      videoId: videoId,
      points: points,
    });
    if (response.status !== 200) {
      throw new Error("Submit feedback failed");
    }
  } catch (error) {
    console.error("Submit feedback failed:", error);
  }
};

export const getAdvertisers = async (): Promise<Advertiser[]> => {
  try {
    const response = await api.get("/admin/advertise", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Get advertisers failed:", response);
      return [];
    }
  } catch (error) {
    console.error("Get advertisers failed:", error);
    return [];
  }
};

export const addAdvertiser = async (advertiser: Advertiser): Promise<string> => {
  try {
    const response = await api.post("/admin/advertise", advertiser, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (response.status === 200) {
      return response.data.id;
    } else {
      console.error("Add advertiser failed:", response);
      return "-1";
    }
  } catch (error) {
    console.error("Add advertiser failed:", error);
    return "-1";
  }
};

export const addCampaign = async (campaign: Campaign): Promise<string> => {
  try {
    const response = await api.post(`/admin/advertise/${campaign.advertiserId}/campaigns`, campaign, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (response.status === 200) {
      return response.data.id;
    } else {
      console.error("Add campaign failed:", response);
      return "-1";
    }
  } catch (error) {
    console.error("Add campaign failed:", error);
    return "-1";
  }
};


